import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import useEvent from '@modules/event/hooks/useEvent';
import { usePages } from '@modules/event/hooks/usePages';
import { useAppSelector } from '@common/store';

export default function Home() {
  const router = useRouter();
  const { isAuthorized, isAccepted, privacyPolicyAccepted, hasRequiredFieldsFilled } =
    useAppSelector((state) => state.auth);
  const event = useEvent();
  const { data: pages } = usePages();

  useEffect(() => {
    if (isAuthorized && isAccepted && privacyPolicyAccepted && hasRequiredFieldsFilled && pages) {
      if (event?.initialPageSlug) {
        router.push({ pathname: `/pages/${event.initialPageSlug}`, query: router.query });
      } else {
        const firstSlug = pages.find((page) => page.slug)?.slug;
        if (firstSlug) router.push({ pathname: `/pages/${firstSlug}`, query: router.query });
      }
    }
  }, [
    isAuthorized,
    isAccepted,
    privacyPolicyAccepted,
    hasRequiredFieldsFilled,
    pages,
    event,
    router,
  ]);

  if (isAuthorized && !isAccepted && privacyPolicyAccepted) return <p>Not yet accepted</p>;

  if (!isAuthorized) {
    if (process.env.NEXT_PUBLIC_SAML_AUTH === 'true') {
      router.push({ pathname: '/auth/saml' });
      return null;
    }
  }
  router.push({ pathname: '/landing' });
  return null;
}
